import React, {useState, useEffect, useMemo} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/icons/HelpOutline";
import TextField from "@material-ui/core/TextField";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import MuiSelect from "commons-ui/core/MuiSelect";
import renderHTML from "react-render-html";
import {KeyboardDatePicker} from "@material-ui/pickers";
import "./style.styl";




export default function AirForm(props) {
    const {values, description, itemId, modelName, readOnly, itemIdParent, itemIdValueParent, errors, touched, onBack, setFieldValue, setValues, isSubmitting, apiPath} = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);


    const request = () => {
        dataSourceV2(`${modelName}_list?${itemIdParent}=${itemIdValueParent}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item_list && v.item_list.length) {
                    setItems(v.item_list);
                }
                setLoading(false);
            });
    };

    const item = useMemo(() => {
        const itemList = items.filter(v => v[itemId] === values[itemId]);
        if (itemList.length) return itemList[0];
        return {};
    }, [values[itemId], items.length]);


    useEffect(() => {
        if (itemIdValueParent) request();
    }, [itemIdValueParent]);

    useEffect(() => {
        if (!readOnly) setFieldValue("min_order", item.min_order || props.item.min_order || 0);
    }, [props.item.min_order, item.min_order]);

    useEffect(() => {
        if (!readOnly) setFieldValue("delivery_amount", item.cost || 0);
    }, [item.cost]);

    useEffect(() => {
        if (!readOnly) setFieldValue("packaging_amount", item.packaging_cost || props.item.packaging_cost || 0);
    }, [item.packaging_cost, props.item.packaging_cost]);

    useEffect(() => {
        if (!readOnly) setFieldValue("tax_rate_global", item.tax_rate_percent || 0);
    }, [item.tax_rate_percent]);

    useEffect(() => {
        setFieldValue("ecommerce_store_delivery_option_rule", item);
    }, [Object.keys(item).length]);


    if (loading) {
        return (
            <Loading className="u-marginBottom100"/>
        );
    }

    return (
            <React.Fragment>
                {/*<List className="u-marginLeftNegative15 u-marginRightNegative15">
                    {
                        items.map((v, i) => {
                            const selected = v[itemId] === values[itemId]
                            return (
                                <ListItem   selected={selected} button key={i} className="u-textColorNormal u-fontSize12"
                                            onClick={() => {setFieldValue(itemId, selected ? "" : v[itemId]); setFieldValue("delivery_airport_name", v.name)}}>
                                    {i + 1}. {v.name}
                                </ListItem>
                            )
                        })
                    }
                </List>*/}
                <div className="u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
                    <div className="u-sizeFullWidth u-maxWidth400">
                        <div className="u-flexColumn">
                            <MuiSelect
                                items={items.map(v => ({name: v.name, value: v[itemId]}))}
                                selected={values[itemId]}
                                htmlFor={"airport_field"}
                                label="Select Airport"
                                disabled={readOnly}
                                error={errors[itemId] && touched[itemId]}
                                helperText={errors[itemId] && touched[itemId] ? errors[itemId] : ""}
                                className="u-sizeFullWidth u-marginVertical12"
                                onSelect={(value, item) => {setFieldValue(itemId, value); setFieldValue("delivery_airport_name", item.name);}}
                            />

                            <KeyboardDatePicker
                              disableToolbar
                              disablePast
                              variant="inline"
                              className="u-sizeFullWidth u-marginVertical12"
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              margin="dense"
                              disabled={readOnly}
                              id="date-picker-inline"
                              label="Requested Pick Up Date"
                              error={errors.delivery_requested_date && touched.delivery_requested_date}
                              helperText={(errors.delivery_requested_date && touched.delivery_requested_date) ? errors.delivery_requested_date : undefined}
                              value={values.delivery_requested_date ? new Date(values.delivery_requested_date) : null}
                              onChange={(date) => !date || isNaN(date.getTime()) ? null : setFieldValue("delivery_requested_date", date.toISOString())}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />


                            <TextField
                                label="Comments (Special Instructions)"
                                className="u-sizeFullWidth u-marginVertical12 u-hide"
                                margin="dense"
                                multiline
                                rows={2}
                                disabled={readOnly}
                                error={errors.customer_note && touched.customer_note}
                                helperText={errors.customer_note && touched.customer_note ? errors.customer_note : ""}
                                onChange={(e) => setFieldValue("customer_note", e.target.value)}
                                value={values.customer_note}
                                variant="outlined"
                            />
                        </div>


                        <div className="u-marginTop8 u-fontSize10 u-sizeFullWidth">
                            Delivery Cost: {values[itemId] ? ("$" + (parseFloat(item.cost) || 0).toFixed(2)) : <span className="MuiFormHelperText-root Mui-error u-fontSize10">Select Airport</span>}
                        </div>

                        {
                            values[itemId] && (item.packaging_cost || props.item.packaging_cost) ?
                                <div className="u-marginTop8 u-fontSize10  u-sizeFullWidth">
                                    Packaging Cost: {"$" + (parseFloat(item.packaging_cost) || parseFloat(props.item.packaging_cost) || 0).toFixed(2)}
                                </div>
                                :
                                null
                        }

                        {
                            item.min_order || props.item.min_order ?
                                <div className="u-flexColumn u-marginTop8 u-fontSize10 u-sizeFullWidth">
                                    <span className="u-flexCenter">
                                        Min. Order: {"$" + (parseFloat(item.min_order) || parseFloat(props.item.min_order) || 0).toFixed(2)}
                                        <Tooltip title={"Min. order of in-house items before tax"}>
                                            <HelpOutline fontSize="small" className="u-textColorNormal u-marginLeft5"/>
                                        </Tooltip>
                                    </span>
                                    <span>{errors.min_order && touched.min_order ? <div className="MuiFormHelperText-root Mui-error">{errors.min_order}</div> : ""}</span>
                                </div>
                                :
                                null
                        }

                        {
                            item.max_order || props.item.max_order ?
                                <div className="u-marginTop8 u-fontSize10  u-sizeFullWidth">
                                    Max. Order: {"$" + (parseFloat(item.max_order) || parseFloat(props.item.max_order) || 0).toFixed(2)}
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="u-sizeFullWidth u-maxWidth400">
                        <div className="u-textColorNormal u-marginTop8 u-xs-marginTop25 u-fontSize13">
                            {(item.description || description) ? renderHTML(item.description || description) : ""}
                        </div>
                    </div>
                </div>





            </React.Fragment>
    );
}
